.container {
  min-height: 400px;
  min-width: 300px;
  background-image: url(../assets/shea.png);
  background-repeat: no-repeat;
  border: black 2px solid;
  // background-position: center;
  background-size: contain;
  position: relative;
  .leftEye {
    @extend %eyes;
    background-image: url(../assets/lefteye.png);
    left: 150px;
    top: 102px;
    min-height: 35px;
    min-width: 35px;
  }
  .rightEye {
    @extend %eyes;
    background-image: url(../assets/righteye.png);
    left: 201px;
    top: 110px;
    min-height: 28px;
    min-width: 28px;
  }
}
%eyes {
  // background-image: url(../assets/eye.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  border-radius: 50%;
  transition: 0.4s;
  z-index: 3;
}
